<template>
  <ol class="pa-0" style="overflow-y: auto; list-style-type: none">
    <li
      v-for="(step, index) of steps"
      :key="index"
      style="height: 70px"
      class="text-body-1 font-weight-bold mb-5 d-flex align-center"
    >
      <div class="d-flex flex-column" style="position: relative">
        <div class="number rounded-circle align-content-center justify-center align-center my-auto">
          {{ index + 1 }}
        </div>
        <div v-if="index < steps.length - 1" class="divider" />
      </div>
      <div class="font-weight-regular ml-3 my-0" style="max-width: 90%">
        {{ step }}
      </div>
    </li>
  </ol>
</template>

<script lang="ts">
import { Component, Prop } from "vue-property-decorator";
import AbstractController from "@/controllers/abstracts/AbstractController";

@Component({ name: "Steps" })
export default class Steps extends AbstractController {
  @Prop(Array) steps!: string[];
}
</script>

<style scoped>
.number {
  color: white;
  background-color: var(--v-primary-base) !important;
  width: 35px !important;
  height: 35px !important;
  display: flex;
  z-index: 1;
}

.divider {
  border-right: 1px solid grey;
  height: 80px;
  display: inline;
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
